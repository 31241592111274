window.StoreConnect = window.StoreConnect || {};

document.addEventListener("DOMContentLoaded", () => {
  const reCheck = document.getElementById("re-check");
  const showPayment = document.getElementById("show-payment");
  const paymentsContainer = document.getElementById("sc-payments-methods-container");
  const alreadyPaid = document.getElementById('sf-already-paid');
  const alreadyPaidButtons = document.getElementById('already-paid-buttons');

  if (showPayment) {
    showPayment.addEventListener("click", event => {
      paymentsContainer.classList.remove("sc-hide");
      alreadyPaid.classList.add("sc-hide");
      alreadyPaidButtons.classList.add("sc-hide");
    });
  }
});
